import PageMain from "@/components/PageMain.vue";
import Identify from "@/components/Identify";
import UploadImage from '@/components/UploadImage.vue'
import ViewImage from "@/components/ViewImage.vue";
import Editor from "@/components/Editor.vue";
export default function (app) {
    app.component('ViewImage', ViewImage)
    app.component('UploadImage', UploadImage)
    app.component('PageMain', PageMain)
    app.component('Identify', Identify)
    app.component('Editor', Editor)
}
