import { createStore } from 'vuex'
import useStorage from "@/utils/useStorage";
import { http} from "@/api";
import {defaultMockMenu} from "@/utils/common";
const storage = useStorage()
export default createStore({
  state: {
    token: storage.getItem('askjina_web_token') || '',
    userInfo: storage.getItem('askjina_web_userInfo') || {},
      menuList: storage.getItem('askjina_web_menuList') || []
  },
  getters: {
    token: (state) => state.token,
  userName: (state) => state.userInfo.nickname || '未知用户',
  userInfo: (state) => state.userInfo || {},
    menuList: (state) => state.menuList,
  },
  mutations: {
       setToken(state, token) {
            state.token = token
       },
        setUserInfo(state, userInfo) {
            state.userInfo = userInfo
        },
        setMenuList(state, menuList) {
             state.menuList = menuList
        },
  },
  actions: {
    login({ commit }, data) {
      return new Promise((resolve, reject) => {
        http.login(data).then(res => {
            const { token } = res.data
            let menuList = []
            if (res.data.type === 'user') {
                menuList = JSON.parse(JSON.stringify(defaultMockMenu)).filter(item => item.code !== 'admin')
            } else {
                menuList = JSON.parse(JSON.stringify(defaultMockMenu))
            }
            storage.setItem('askjina_web_menuList', menuList)
            storage.setItem('askjina_web_token', token)
            storage.setItem('askjina_web_userInfo', res.data)
            commit('setToken', token)
            commit('setUserInfo', res.data)
            commit('setMenuList', menuList)
            resolve(res.data)
        }).catch(err => {
            reject(err)
        })
      })
    },
    resetState({ commit }) {
      return new Promise((resolve, reject) => {
        storage.removeItem('token')
        storage.removeItem('userInfo')
        storage.removeItem('menuList')
        commit('setToken', '')
        commit('setUserInfo', {})
        commit('setMenuList', [])
        resolve()
      })
    },
  },
  modules: {
  }
})
