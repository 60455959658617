import request from '@/utils/useRequest'
export const httpUpload = (file) => {
    let formData = new FormData()
    formData.append('file', file)
    return request({
        url: '/upload/file',
        method: 'post',
        data: formData
    })
}
const getApi = (api) => {
    return  {
        list: (data) => {
            return request({
                url: `/${api}/list`,
                params: data,
                method: 'get'
            })
        },
            page: (data) => {
            return request({
                url: `/${api}`,
                params: data,
                method: 'get'
            })
        },
            read: (id) => {
            return request({
                url: `/${api}/` + id,
                method: 'get'
            })
        },
            update: (data) => {
            return request({
                url: `/${api}/` + data.id,
                data,
                method: 'put'
            })
        },
            create: (data) => {
            return request({
                url: `/${api}`,
                data,
                method: 'post'
            })
        },
            del: (id) => {
            return request({
                url: `/${api}/` + id,
                method: 'delete'
            })
        },
    }
}
export const http = {
    login: (data) => {
        return request({
            url: '/admin/login',
            data,
            method: 'post'
        })
    },
    consume: {
        ...getApi('consume'),
    },
    recharge: {
        ...getApi('recharge'),
    },
    user: {
        ...getApi('user')
    },
    admin: {
        ...getApi('admin')
    },
    member: {
        ...getApi('member')
    },
    message: {
        ...getApi('message')
    }
}
