<script setup>
import {computed} from "vue";

const props = defineProps({
  imageUrl: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: ''
  },
  customStyle: {
    type: Object,
    default: () => {}
  }
})
const style = computed(() => {
  if (props.type === 'image') {
    return {
      width: '120px',
      height: '60px'
    }
  }
  if (props.type === 'avatar') {
    return {
      width: '50px',
      height: '50px'
    }
  }
  return {
    ...props.customStyle
  }
})
</script>

<template>
  <el-image :style="style" :src="imageUrl" preview-teleported :preview-src-list="[imageUrl]">
    <template #error>
      暂无图片
    </template>
  </el-image>
</template>

<style scoped lang="scss">
.el-image {
  display: flex;
  align-items: center;
}
</style>