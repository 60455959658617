export const defaultMockMenu = [
    {
        name: '首页',
        code: 'home',
    },
    {
        name: '充值记录',
        code: 'recharge',
    },
    {
        name: '消费记录',
        code: 'consume',
    },
    {
        name: '聊天记录',
        code: 'message',
    },
    {
        name: '用户管理',
        code: 'user',
    },
    {
        name: '管理员',
        code: 'admin',
    }
]
