import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementPlus, { ElInput, ElDatePicker } from 'element-plus'
import 'element-plus/dist/index.css'
import '@/style/index.css'
import zhCn from 'element-plus/dist/locale/zh-cn.mjs'
import useComponent from '@/components/index'
import '@/permission'
const app = createApp(App)
useComponent(app)
console.log(ElInput)
ElInput.props.placeholder.default = '请输入内容'
ElDatePicker.props.placeholder.default = '请选择时间'
app.use(ElementPlus, {  zIndex: 3000, locale: zhCn })

app.use(store).use(router).mount('#app')
