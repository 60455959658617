<script setup>
import {Plus} from "@element-plus/icons-vue";
import {httpUpload} from "@/api";

const props = defineProps({
  image: {
    type: String,
    default: ''
  },
  type: {
    type: String,
    default: 'rect', // 正方形 square 长方形 rect
  }
})
const emits = defineEmits(['update:image'])

const uploadImage = (file) => {
  // 上传头像
  httpUpload(file.file).then(res => {
    emits('update:image', res.data.url)
  })
}
</script>

<template>
  <el-upload
      :class="['image-uploader', type]"
      action="#"
      :http-request="uploadImage"
      :show-file-list="false"
      accept="image/*"
  >
    <el-image v-if="image" :src="image" class="image" />
    <el-icon v-else class="image-uploader-icon"><Plus /></el-icon>
  </el-upload>
</template>

<style lang="scss">
.image-uploader {
  width: 100%;
}
.image-uploader.rect {
  width: 100%;
}
.image-uploader.square {
  width: 120px;
  height: 120px;
}
.image-uploader .image {
  width: 100%;
  height: 120px;
  display: block;
}
.image-uploader .el-upload {
  border: 2px dashed var(--el-border-color);
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  transition: var(--el-transition-duration-fast);
}

.image-uploader .el-upload:hover {
  border-color: var(--el-color-primary);
}
.el-upload {
  width: 100%;
}

.el-icon.image-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 100%;
  height: 120px;
  text-align: center;
}
</style>