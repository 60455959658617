<script setup>
import { useRouter } from "vue-router";
import { Back } from "@element-plus/icons-vue";
const router = useRouter()

const props = defineProps({
  title: String,
  isBack: {
    type: Boolean,
    default: false
  }
})
const back = () => {
  router.back()
}
</script>

<template>
  <el-card class="pagemain-card" shadow="never">
    <template #header>
      <div class="card-header">
        <span>{{ title }}</span>
        <el-button v-if="isBack" :icon="Back" @click="back">返回</el-button>
      </div>
    </template>
    <slot></slot>
  </el-card>
</template>

<style lang="scss">
.pagemain-card {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  &.el-card {
    .el-card__header {
      flex-shrink: 0;
    }
    .el-card__body {
      flex: 1;
      overflow: auto;
    }
    .card-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
</style>