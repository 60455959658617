<script setup>
import { shallowRef, ref, nextTick, watch } from "vue";
import '@wangeditor/editor/dist/css/style.css' // 引入 css
import { Editor, Toolbar } from '@wangeditor/editor-for-vue'
import { useStore } from "vuex";
import { httpUpload } from "@/api";
const store = useStore()
const valueHtml = ref('')
const emit = defineEmits(['change', 'update:modelValue'])
const props = defineProps({
  modelValue: ''
})
watch(
    () => props.modelValue,
    (val) => {
      if (val === valueHtml.value) return
      valueHtml.value = val
    },
    {
      immediate: true
    }
)
watch(
    () => valueHtml.value,
    (val) => {
      console.log('双向绑定')
      emit('update:modelValue', val)
    }
)

function getPhotoUrl() {}
// 富文本实例对象
const editorRef = shallowRef()
// 菜单配置
const toolbarConfig = ref({})
// 编辑器配置
const editorConfig = ref({
  placeholder: '请输入内容...',
  readOnly: false,  // 只读
  MENU_CONF: {
    // 配置上传图片
    uploadImage: {
      // server: BaseUrl + '/norms/upload/singleUploadFile', // 配置图片上传地址
      maxFileSize: 2 * 1024 * 1024, // 10M  图片大小限制
      fieldName: 'file', // 上传名字
      allowedFileTypes: [], // 选择文件时的类型限制，默认为 ['image/*'] 。如不想限制，则设置为 []
      // 自定义上传参数，传递图片时需要带一些参数过去写在这。参数会被添加到 formData 中，一起上传到服务端。
      // meta: {
      //   image_class_id: "2",
      //   file_type: "1",
      // },
      // 自定义设置请求头，比如添加token之类的
      // headers: {
      //   'Authorization': store.getters.token
      // },
      // 上传进度的回调函数，可以用来显示进度条
      onProgress(progress) {
        // progress 是 0-100 的数字
        console.log('progress', progress)
      },
      // // 单个文件上传成功之后
      // onSuccess(file, res) {
      //     console.log(`${file.name} 上传成功`, res)
      // },

      // 单个文件上传失败
      onFailed(file, res) {
        console.log(`${file.name} 上传失败`, res)
      },

      // 上传错误，或者触发 timeout 超时
      onError(file, err, res) {
        console.log(`${file.name} 上传出错`, err, res)
      },

      // 插入图片到富文本编辑器回显
      // customInsert(res, insertFn) {
      //   console.log()
      //   // res 即服务端的返回结果
      //   getPhotoUrl().then((res) => {
      //     const url = res.data
      //     const alt = ''
      //     const href = res.data
      //     // 从 res 中找到 url alt href ，然后插入图片
      //     insertFn(url, alt, href)
      //   })
      // },
      async customUpload(file, insertFn) {
        // 文件上传
        const res = await httpUpload(file)
        insertFn(res.data.url)
      }
    },
    // 配置上传视频
    uploadVideo: {
      server: 'http://111.198.10.15:21409/minio/file/upload', // 配置视频上传地址
      maxFileSize: 5 * 1024 * 1024, // 5M  视频大小限制
      fieldName: 'multipartFile', // 上传名字
      // 最多可上传几个文件，默认为 5
      // maxNumberOfFiles: 1,
      allowedFileTypes: [], // 选择文件时的类型限制，默认为 ['video/*'] 。如不想限制，则设置为 []
      // 自定义上传参数，传递图片时需要带一些参数过去写在这。参数会被添加到 formData 中，一起上传到服务端。
      // meta: {
      //   type: 1,
      // },
      // 自定义设置请求头，比如添加token之类的
      // headers: {
      //     Accept: 'text/x-json',
      //     otherKey: 'xxx'
      // },
      // metaWithUrl: false, // 将 meta 拼接到 url 参数中，默认 false
      // withCredentials: true, // 跨域是否传递 cookie ，默认为 false
      // 上传之前触发
      onBeforeUpload(file) {
        // file 选中的文件，格式如 { key: file }
        return file
        // 可以 return
        // 1. return file 或者 new 一个 file ，接下来将上传
        // 2. return false ，不上传这个 file
      },

      // 上传进度的回调函数，可以用来显示进度条
      onProgress(progress) {
        // progress 是 0-100 的数字
        console.log('progress', progress)
      },

      // // 单个文件上传成功之后
      onSuccess(file, res) {
        console.log(`${file.name} 上传成功`, res)
      },

      // 单个文件上传失败
      onFailed(file, res) {
        console.log(`${file.name} 上传失败`, res)
      },

      // 上传错误，或者触发 timeout 超时
      onError(file, err, res) {
        console.log(`${file.name} 上传出错`, err, res)
      },

      // 插入图片到富文本编辑器回显
      customInsert(res, insertFn) {
        console.log(res, '视频插入')
        // res 即服务端的返回结果
        // let url = res.data;
        // let poster = res.data.poster;
        // 从 res 中找到 url poster ，然后插入
        // 参数url是视频地址，poster是视频封面图片，后端如果不返回，可以考虑写死一个固定的封面图
        getPhotoUrl(res.data[0]).then((res) => {
          const url = res.data
          // 从 res 中找到 url alt href ，然后插入图片
          insertFn(url, '')
        })
      },
      async customUpload(file, insertFn) {
        // 文件上传
        const res = await httpUpload(file)
        insertFn(res.data)
      }
    },
  },
})
const onCreated = (editor) => {
  editorRef.value = editor
  nextTick(() => {
    editorRef.value = editor // 一定要用 Object.seal() ，否则会报错
  })
}
</script>

<template>
  <div style="border: 1px solid #dcdfe6;width: 100%;border-radius: 4px;">
    <toolbar
        style="border-bottom: 1px solid #dcdfe6;width: 100%;border-radius: 4px;"
        :editor="editorRef"
        :default-config="toolbarConfig"
        mode="default"
    />
    <editor
        v-model="valueHtml"
        style="height: 300px; overflow-y: hidden;"
        :default-config="editorConfig"
        mode="default"
        @onCreated="onCreated"
    />
  </div>
</template>