import { createRouter, createWebHistory } from 'vue-router'
const routes = [
     {
    path: '/',
    name: 'layout',
      redirect: '/home',
    component:  () => import('@/Layout/index.vue'),
    children: [
        {
            name: 'home',
            path: '/home',
            component:  () => import('@/views/home.vue'),
        },
        {
            name: 'user',
            path: '/user',
            component:  () => import('@/views/user/index.vue'),
        },
        {
            name: 'userSave',
            path: '/user/save/:id?',
            component:  () => import('@/views/user/save.vue'),
        },
        {
            name: 'recharge',
            path: '/recharge',
            component:  () => import('@/views/recharge/index.vue'),
        },
        {
            name: 'consume',
            path: '/consume',
            component:  () => import('@/views/consume/index.vue'),
        },
        {
            name: 'admin',
            path: '/admin',
            component:  () => import('@/views/admin/index.vue'),
        },
        {
            name: 'adminSave',
            path: '/admin/save/:id?',
            component:  () => import('@/views/admin/save.vue'),
        },
        {
            name: 'message',
            path: '/message',
            component:  () => import('@/views/message/index.vue'),
        },
    ]
  },
    {
        path: '/login',
        name: 'login',
        component:  () => import('@/views/Login.vue'),
    }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
