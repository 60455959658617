/**
 * 路由拦截器
 */
import router from './router'
import store from './store'
const withePath = ['/login', '/register']
router.beforeEach((to, from, next) => {
    if (withePath.includes(to.path)) {
        next()
        return
    } else {
        if (!store.getters.token || !store.getters.userInfo.id) {
            next({
                path: '/login'
            })
            return
        }
    }
    next()
})
